import React, { Component } from 'react';
import './defaultFooter.css'; 
import moment from 'moment';
import LanguageContext from '../../../context/LanguageProvider';

export class DefaultFooter extends Component {
    static contextType = LanguageContext; 
  render() {
    const { lang, language } = this.context;
    return (
        <section>
            <div className="calendar-footer-section">
                <div className="container">
                    <div className="row bottom-copyright-section justify-content-center align-items-center">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <p className="text-white small-text-gray text-center mb-0 d-flex justify-content-center align-items-center flex-wrap">
                                {lang('copyright')} &#169;  {moment().format('YYYY')} Demo <span><pre className='text-white'>&#174;</pre></span> {lang('all_rights_reserved.copy_and_Distribution_are_prohibited.')}
                                {/* Copyright &copy; 2022 <a href="" style={{textDecoration: "none"}}><b style={{color: "#ffffff",fontWeight:"300"}}>DNS</b></a>. All Rights Reserved. Site by
                                <a href="" style={{textDecoration: "none"}}><b style={{color: "#ffffff", fontWeight:"300"}}>driveNscan Team</b></a> */}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
  }
}

export default DefaultFooter;