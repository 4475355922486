let driveTypeObj = {
    1: "drive_through",
    2: "drive_in",
    3: "mini_drive_in",
    4: "rental_scanners",

}

const getDriveType = (num) => {
    return driveTypeObj[num] ?? ''
};

export default getDriveType;