import React, { Component } from 'react';
import './appBar.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, FormGroup, Label, Input, Spinner } from 'reactstrap';
import DateRangePicker from "react-bootstrap-daterangepicker";
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'bootstrap/dist/css/bootstrap.css';
import moment from 'moment';
import axios from 'axios';
import { configuration } from '../../../helper/appConfig';
import toastAlert from '../../../helper/ToastAlert/ToastAlert';
import Cookies from "js-cookie";
import { withRouter } from '../../../helper/withRouter';
import { handleAuthTokenError } from '../../../redux/actions/authActions';
import { connect } from 'react-redux';
import LanguageContext from '../../../context/LanguageProvider';
import MobileInput from '../mobileInput/MobileInput';
import getDriveType from '../../../helper/getDriveType';
const mobileNumberRegex = {
    '49': /\(?\+\(?49\)?[ ()]?([- ()]?\d[- ()]?){10}/, //germany
    '41': /^([0][1-9][0-9](\s|)[0-9][0-9][0-9](\s|)[0-9][0-9](\s|)[0-9][0-9])$|^(([0][0]|\+)[1-9][0-9](\s|)[0-9][0-9](\s|)[0-9][0-9][0-9](\s|)[0-9][0-9](\s|)[0-9][0-9])$/, //swiss
    '43': /((0043)\s?|(\+43)\s?)((\d{3})\s?)\d{3,12}$/, //Austria
    '39': /^(\((00|\+)39\)|(00|\+)39)?(38[890]|34[7-90]|36[680]|33[3-90]|32[89])\d{7}$/, //Italy
    '386': /^\+?(386)?0([1-7][0-9]{7}|([347]0|[3457]1|6[4589]){6})$/, //Slovenia
    "31": "/^(((\\+31|0|0031)6){1}[1-9]{1}[0-9]{7})$", //Netherlands
    "33" :"/^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/", //France
    '40' : /^(\+4|)?(07[0-8]{1}[0-9]{1}|02[0-9]{2}|03[0-9]{2}){1}?(\s|\.|\-)?([0-9]{3}(\s|\.|\-|)){2}$/, //Romania
    '420': /^(\+?420)?(2[0-9]{2}|3[0-9]{2}|4[0-9]{2}|5[0-9]{2}|72[0-9]|73[0-9]|77[0-9]|60[1-8]|56[0-9]|70[2-5]|79[0-9])[0-9]{3}[0-9]{3}$/, //Czech Republic(Czechia)
    '1': /^(1\s?)?(\d{3}|\(\d{3}\))[\s\-]?\d{3}[\s\-]?\d{4}$/ //USA
};

const driveTypePlaceHolder = {
    1: "enter_drive-through_name",
    2: "enter_drive-in_name",
    3: "enter_mini_drive-in_name",
    4: "enter_rental_scanner_name"
}

export class AppBar extends Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            typeOfDriveIn: 1,
            location: "",
            locationMessage: '',
            houseNo: "",
            streetName: "",
            address_1: "",
            address_2: "",
            postalCode: "",
            city: "",
            country: "",
            clientContactPerson: "",
            clientContactNumber: "",
            error: [],
            startDate: moment().add('days', 1),
            endDate: moment().add('days', 1),
            selectedCountry: {},
        };
    };

  toggle = () => {
    // for create appointment modal
    const { modal } = this.state;
    this.setState({
        modal: !modal,
        error: [],
        typeOfDriveIn: 1,
        location: "",
        locationMessage: "",
        houseNo: "",
        streetName: "",
        address_1: "",
        address_2: "",
        postalCode: "",
        city: "",
        country: "",
        clientContactPerson: "",
        clientContactNumber: "",
        startDate: moment().add('days', 1),
        endDate: moment().add('days', 1),
    },() => {
        if(this.state.modal && (this?.props?.authData?.organizationCountry)) {
            const { monthAndYear } = this.props;
            const { endDate, startDate } = this.state;
            let momentMonthAndYear = moment(monthAndYear,'MM/YYYY');
            this.setState({
                country: this?.props?.authData?.organizationCountry || '',
                endDate: moment().set({date: 1, month: momentMonthAndYear.format('MM') - 1}).format('MM') === endDate.format('MM') ?  endDate : moment().set({date: 1, month: momentMonthAndYear.format('MM') - 1, year: momentMonthAndYear.format('YYYY')}),
                startDate: moment().set({date: 1, month: momentMonthAndYear.format('MM') - 1}).format('MM') === endDate.format('MM') ? startDate : moment().set({date: 1, month: momentMonthAndYear.format('MM') - 1, year: momentMonthAndYear.format('YYYY')})
            })
        }
    }); 
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
        [name]: value,
    }
    // ,() => {
    //     if(name === 'location') {
    //         this.isLocationExistsOrNot()
    //     }
    // }
    );
  }

  isLocationExistsOrNot = () => {
    const { location } = this.state;
    if(location) {
        let url = configuration.isLocationExistingOrNot;
        const headers = {
            Authorization: `Bearer ${Cookies.get("authToken")}`,
        };
        let payload = {
            location_name: location,
        };
        this.setState({
            loading: true
        })
        axios.post(url,payload,{headers})
        .then((res) => {
           if(res.data.success) {
            this.setState({
                locationMessage: '',
                locationIsAlreadyExists: false,
                loading: false
            })
           }
        })
        .catch((err) => {
            if(err?.response?.data?.code === 422) {
                this.setState({
                    locationMessage: err?.response?.data?.message.replaceAll('.',''),
                    locationIsAlreadyExists: true,
                    loading: false
                })
            } else {
                this.setState({
                    locationIsAlreadyExists: false,
                    locationMessage: '',
                    loading: false
                })
                if (err.response.data.code === 401) {
                    this.props.handleAuthTokenError(this.props);
                } 
                toastAlert(err?.response?.data?.message ? err?.response?.data?.message : "Something went wrong. Please try again later.","error");
            } 
            console.log(err.response.data.message,"response message",err.response.data.code,'code')
        })
    } else {
        this.setState({
            locationIsAlreadyExists: false,
            locationMessage: '',
            loading: false
        })
    }
  }

  handleSelect(event,picker){
    this.setState({
        startDate: picker.startDate, 
        endDate: picker.endDate 
    })
  }

  create = async () => {
    const { typeOfDriveIn, location, locationIsAlreadyExists, streetName, address_1, address_2, houseNo, postalCode, city, country, clientContactPerson, clientContactNumber, startDate, endDate, selectedCountry} = this.state;
    let error = [];

    if(!location?.trim()) {
        error.push("emptyLocation");
    }

    if(locationIsAlreadyExists) {
        error.push("locationIsAlreadyExists");
    }

    if(!streetName?.trim()) {
        error.push("emptyStreetName");
    }

    // if(!address_1?.trim()) {
    //     error.push("emptyAddress_1");
    // }

    // if(!address_2?.trim()) {
    //     error.push("emptyAddress_2");
    // }

    if(!houseNo?.trim()) {
        error.push("emptyHouseNo");
    }

    if(!postalCode?.trim()) {
        error.push("emptyPostalCode");
    }

    // if(postalCode && postalCode.length !== 6) {
    //     error.push("invalidPostalCode");
    // }

    if(!city?.trim()) {
        error.push("emptyCity");
    }

    if(!country?.trim()) {
        error.push("emptyCountry");
    }

    // if(!clientContactPerson?.trim()) {
    //     error.push("emptyClientContactPerson");
    // }

    // if(!clientContactNumber?.trim()) {
    //     error.push("emptyClientContactNumber");
    // } else 
    if(clientContactNumber?.trim() && !this.isMobileNumberValid(clientContactNumber,selectedCountry.dialCode)) {
        error.push("invalidMobileNumber");
    }

    if(error.length) {
        this.setState({
            error
        });
    } else {
        this.setState({
            error: [],
            loading: true
        });
        let payload = {
            driven_type:typeOfDriveIn,
            location_name: location,
            street_name: streetName,
            house_no: houseNo,
            address_1: address_1,
            address_2: address_2,
            postal_code: parseInt(postalCode),
            city,
            country,
            contact_person_name: clientContactPerson,
            contact_person_mobile: clientContactNumber,
            to_date: moment(endDate).format('YYYY-MM-DD'),
            from_date: moment(startDate).format('YYYY-MM-DD'),
        };
        let url = configuration.createLocation;
        const headers = {
            Authorization: `Bearer ${Cookies.get("authToken")}`,
        };
        await axios.post(url,payload,{headers})
        .then((res) => {
            if(res?.data?.success) {
                Cookies.set('monthAndYear',moment(startDate).format("MM/YYYY"));
                this.setState({
                    loading: false,
                    modal: !this.state.modal,
                    typeOfDriveIn: 1,
                    location: "",
                    locationIsAlreadyExists: false,
                    locationMessage: "",
                    houseNo: "",
                    streetName: "",
                    address_1: "",
                    address_2: "",
                    postalCode: "",
                    city: "",
                    country: "",
                    clientContactPerson: "",
                    clientContactNumber: "",
                    startDate: moment().add('days', 1),
                    endDate: moment().add('days', 1),
                })
                toastAlert(res?.data?.message ? res?.data?.message : "Data saved successfully.","success")
            }
        })
        .then(() => {
            this.props.getAllLocation(true);
        })
        .catch((err) => {
            console.log(err);
            this.setState({
                loading: false,
            })
            if(err.response.data.code === 401) {
                this.props.handleAuthTokenError(this.props);
            }
            toastAlert(err?.response?.data?.message ? err?.response?.data?.message : "Something went wrong. Please try again later.","error")
        });
    };
  };

  handleMobileInputChange = (val,data) => {
    this.setState({
        clientContactNumber: val.charAt(0) === '+' ? val : `+${val}`,
        selectedCountry: data
    })
  };

  isMobileNumberValid = (value,countryCode) => {
    if(countryCode === value.replaceAll('+','')) {
        return true;
    }

    if(value.length > 3) {
        const regexString = mobileNumberRegex[countryCode] || '';
        const regex = new RegExp(regexString);
        return regex.test(value);
    } 
  }

  render() {
    const { modal, loading, typeOfDriveIn, location,locationMessage, streetName, address_1, address_2, houseNo, postalCode, city, country, clientContactPerson, clientContactNumber, error, startDate, endDate} = this.state;
    const { handleMonthChange,monthAndYear, loader, isSingleView} = this.props;
    let momentMonthAndYear = moment(monthAndYear,'MM/YYYY');
    let monthName = momentMonthAndYear.format('MMM');
    let year = momentMonthAndYear.format('YYYY');
    const { lang,language } = this.context;
    return (
        <>
            <section>
                <div className="appbar-section">
                    <div className="container">
                        <div className="row d-flex align-items-center">
                            <div className="col-lg-4 col-md-6 col-sm-12 d-flex align-items-center">
                                <div className="mr-3">
                                    <button className="icon_plus_btn" data-toggle="modal" onClick={() => this.toggle()} ><i className="fa fa-plus"></i></button>
                                </div>
                                <div className="appbar-title-section">
                                    <h2 className="main-heading m-0">{lang("overview_planning_drive-in")}</h2>
                                </div>
                            </div>
                            {!isSingleView &&
                                <div className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-lg-center justify-content-md-end justify-content-center align-items-center appbar-tabs">
                                    <a className="left-btn appbar-btn mr-2">
                                        <button className="appbar-btn-in" type="button" onClick={() => handleMonthChange('prev')} disabled={loader}>
                                            <i className="fa fa-angle-left" aria-hidden="true"></i>
                                        </button>
                                    </a>
                                    <div className="appbar-title-section">
                                        <h2 className="main-heading m-0">{monthName}-{year}</h2>
                                    </div>
                                    <a className="right-btn appbar-btn ml-2" >
                                        <button className="appbar-btn-in" type="button" onClick={() => handleMonthChange('next')} disabled={loader}>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </button>
                                    </a>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>

            {/* Add New Week Modal  */}
            <Modal isOpen={modal} toggle={() => this.toggle()} backdrop={true} keyboard={true} size="xl" centered={true} className='create_drivein_modal' scrollable={true}>
                <ModalHeader toggle={() => this.toggle()} 
                    close={<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.toggle()}>
                            <span aria-hidden="true">&times;</span>
                        </button>}
                >
                    {lang("drive_in")}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg={6} md={12} sm={12} className='align-items-center d-flex flex-wrap'>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="typeOfDriveIn" id="drive-through" value={1} checked={parseInt(typeOfDriveIn) === 1} onChange={(e) => this.handleChange(e)}/>
                            <label className="form-check-label" for="drive-through">{lang("drive_through")}</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="typeOfDriveIn" id="drive-in" value={2} checked={parseInt(typeOfDriveIn) === 2} onChange={(e) => this.handleChange(e)}/>
                            <label className="form-check-label" for="drive-in">{lang("drive_in")}</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="typeOfDriveIn" id="mini-drive-in" value={3} checked={parseInt(typeOfDriveIn) === 3} onChange={(e) => this.handleChange(e)}/>
                            <label className="form-check-label" for="mini-drive-in">{lang("mini_drive_in")}</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="typeOfDriveIn" id="rental_scanners" value={4} checked={parseInt(typeOfDriveIn) === 4} onChange={(e) => this.handleChange(e)}/>
                            <label className="form-check-label" for="rental_scanners">{lang("rental_scanners")}</label>
                        </div>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <FormGroup>
                                {/* <Label> {lang(parseInt(typeOfDriveIn) === 1 ? "drive_through" : "drive_in")} Name</Label> */}
                                <Label> {lang(getDriveType(parseInt(typeOfDriveIn)))} Name</Label>
                                <Input
                                    name="location"
                                    value={location}
                                    placeholder={lang(driveTypePlaceHolder[parseInt(typeOfDriveIn)])}
                                    type="text"
                                    onChange={(e) => this.handleChange(e)}
                                    onBlur={() => this.isLocationExistsOrNot()}
                                />
                                {error.includes('emptyLocation') && <span className='text-danger'>{lang("location_is_required")}</span>}
                                {locationMessage?.length ? <span className='text-danger'>{locationMessage} is already exists.</span> : ""}
                            </FormGroup>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <FormGroup>
                                <Label> {lang("street")} </Label>
                                <Input
                                    name="streetName"
                                    value={streetName}
                                    placeholder={lang("enter_street")}
                                    type="text"
                                    onChange={(e) => this.handleChange(e)}
                                />
                                {error.includes('emptyStreetName') && <span className='text-danger'>{lang("street_is_required")}</span>}
                            </FormGroup>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                        <FormGroup>
                            <Label> {lang("house_number")} </Label>
                            <Input
                                name="houseNo"
                                value={houseNo}
                                placeholder={lang("enter_house_number")}
                                type="text"
                                onChange={(e) => this.handleChange(e)}
                            />
                            {error.includes('emptyHouseNo') && <span className='text-danger'>{lang("house_number_is_required")}</span>}
                        </FormGroup>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                        <FormGroup>
                            <Label> {lang("address_line_1")} </Label>
                            <Input
                                name="address_1"
                                value={address_1}
                                placeholder={lang("enter_address_line_1")}
                                type="text"
                                onChange={(e) => this.handleChange(e)}
                            />
                            {error.includes('emptyAddress_1') && <span className='text-danger'>{lang("address_line_1_is_required")}</span>}
                        </FormGroup>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                        <FormGroup>
                            <Label> {lang("address_line_2")} </Label>
                            <Input
                                name="address_2"
                                value={address_2}
                                placeholder={lang("enter_address_line_2")}
                                type="text"
                                onChange={(e) => this.handleChange(e)}
                            />
                            {error.includes('emptyAddress_2') && <span className='text-danger'>{lang('address_line_2_is_required')}</span>}
                        </FormGroup>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                        <FormGroup>
                            <Label> {lang("postal_code")} </Label>
                            <Input
                                name="postalCode"
                                value={postalCode}
                                placeholder={lang('enter_postal_code')}
                                type="number"
                                onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                                onChange={(e) => this.handleChange(e)}
                            />
                            {error.includes('emptyPostalCode') && <span className='text-danger'>{lang('postal_code_is_required')}</span>}
                            {error.includes('invalidPostalCode') && <span className='text-danger'>The postal code must be 6 digits.</span>}
                        </FormGroup>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                        <FormGroup>
                            <Label> {lang("city")} </Label>
                            <Input
                                name="city"
                                value={city}
                                placeholder={lang("enter_city")}
                                type="text"
                                onChange={(e) => this.handleChange(e)}
                            />
                            {error.includes('emptyCity') && <span className='text-danger'>{lang("city_is_required")}</span>}
                        </FormGroup>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                        <FormGroup>
                            <Label> {lang("country")} </Label>
                            <Input
                                name="country"
                                value={country}
                                placeholder={lang("enter_country")}
                                type="text"
                                onChange={(e) => this.handleChange(e)}
                                disabled
                            />
                            {error.includes('emptyCountry') && <span className='text-danger'>{lang('country_is_required')}</span>}
                        </FormGroup>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                        <FormGroup>
                            <Label> {lang("client_contact_person")} </Label>
                            <Input
                                name="clientContactPerson"
                                value={clientContactPerson}
                                placeholder={lang("enter_client_contact_person")}
                                type="text"
                                onChange={(e) => this.handleChange(e)}
                            />
                            {error.includes('emptyClientContactPerson') && <span className='text-danger'>{lang('client_contact_person_is_required')}</span>}
                        </FormGroup>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                        <FormGroup>
                            <Label> {lang("client_contact_number")} </Label>
                            <MobileInput 
                                value={clientContactNumber}
                                onChange={(val,data) => this.handleMobileInputChange(val,data)}
                                country={country}
                                isMobileNumberValid={(value,country) => this.isMobileNumberValid(value,country)}
                                invalidMobileNumber={error.includes('invalidMobileNumber')}
                            />
                            {/* <Input
                                name="clientContactNumber"
                                placeholder={lang("enter_client_contact_number")}
                                type="number"
                                onChange={(e) => this.handleChange(e)}
                            /> */}

                            {error.includes('emptyClientContactNumber') && <span className='text-danger'>{lang('client_contact_number_is_required')}</span>}
                        </FormGroup>
                        </Col>
                        <Col lg={6} md={12} sm={12}>
                        <FormGroup>
                            <Label> {lang("select_period")} </Label>
                            <DateRangePicker
                                initialSettings={{
                                    locale: {
                                        // format: "YYYY-MM-DD"
                                        format: ['DE','CH-DE','SL-SL'].includes(language) ? "DD.MM.YYYY" : "DD-MM-YYYY",
                                        "daysOfWeek": [
                                            "Su",
                                            "Mo",
                                            "Tu",
                                            "We",
                                            "Th",
                                            "Fr",
                                            "Sa",
                                        ],
                                        "monthNames": [
                                            "January",
                                            "February",
                                            "March",
                                            "April",
                                            "May",
                                            "June",
                                            "July",
                                            "August",
                                            "September",
                                            "October",
                                            "November",
                                            "December"
                                        ],
                                        "firstDay": 1,

                                    },
                                    startDate: startDate,
                                    opens: 'right',
                                    drops: 'auto',
                                    closeOnScroll:'true',
                                    endDate: moment().set({date: 1, month: momentMonthAndYear.format('MM') - 1}).format('MM') === endDate.format('MM') ?  endDate : moment().set({date: 1, month: momentMonthAndYear.format('MM') - 1}),
                                    showWeekNumbers: true,
                                    minDate: moment().format('MM') > endDate.format('MM') ? moment () : moment().set({date: 1, month: momentMonthAndYear.format('MM') - 1}).format('MM') === endDate.format('MM') ? startDate : moment().set({date: 1, month: momentMonthAndYear.format('MM') - 1, year: momentMonthAndYear.format('YYYY')})
                                     
                                }}
                                onApply={(event, picker) => this.handleSelect(event,picker)}
                            >
                                <input className="form-control" type="text" placeholder="Enter Select Period"/>
                            </DateRangePicker>
                            {error.includes('emptyPeriod') && <span className='text-danger'>Period is required</span>}
                        </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {/* <Button color="secondary" >Cancel</Button> */}
                    <Button className='btn thm_button' onClick={() => this.create()} disabled={loading}>
                        {loading ? 
                            <Spinner color="light" size={'sm'}/>
                        :    
                            lang("create")
                        }
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
  }
}

const mapStateToProps = (state) => ({
	authData: state.authReducer,
})

export default withRouter(connect(mapStateToProps, { handleAuthTokenError })(AppBar))