import axios from "axios";
import { configuration } from "../../helper/appConfig";
// import { addCollectionInIndexDB, deleteDB } from "../../helper/indexDb";
import toastAlert from "../../helper/ToastAlert/ToastAlert";
import { INITIAL_STATE, LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT_ACTION, SET_LOADER } from "../types/authType";
import Cookies from 'js-cookie'

export const loginUser = (email_id, password, deviceId) => async (dispatch) => {
    dispatch({
		type: SET_LOADER,
		payload: {
            loading: true
        },
	});

    const body = { email_id, password, device_id: deviceId};
	const url = configuration.signIn;
    await axios.post(url,body)
    .then(async (response) => {
        if(response?.data?.success) {
            const {id, access_token, global_organization_id, global_user_id, name, organization_name, organization_country, region_name, 
                // role_name,
                role_id} = response.data.data
            dispatch({
                type: LOGIN_SUCCESS,
                payload: { ...response?.data?.data, email_id,organization_country},
            });
            Cookies.set('authToken',access_token)
            Cookies.set('id',id)
            Cookies.set('globalUserId',global_user_id)
            Cookies.set('isAuthenticated',true)
            Cookies.set('email',email_id)
            Cookies.set('globalOrganizationId',global_organization_id)
            Cookies.set('name',name)
            Cookies.set('organizationName',organization_name)
            Cookies.set('organizationCountry',organization_country)
            Cookies.set('regionName',region_name)
            Cookies.set('roleId',role_id)
            // Cookies.set('roleId',role_id)
            toastAlert(response?.data?.message ? response?.data?.message : "Successfully logged in", 'success');
            // await addCollectionInIndexDB('user',{
            //     // ...response?.data?.data,
            //     authToken: access_token,
            //     id,
            //     globalUserId: global_user_id,
            //     isAuthenticated: true,
            //     email: email_id,
            //     globalOrganizationId: global_organization_id,
            //     name: name,
            //     organizationName: organization_name,
            //     regionName: region_name,
            //     roleType: role_name,
            //     roleId: role_id,
            // })
            // localStorage.setItem('isAuthenticated',true)
        } else {
            dispatch({
                type: LOGIN_FAIL,
                payload: {}
            })
            toastAlert("Something went wrong. Please try again later.", 'error')
        }
    }).catch((err) => {
        dispatch({
            type: LOGIN_FAIL,
            payload: { err }
        })
        toastAlert(err?.response?.data?.message ? err?.response?.data?.message : "Something went wrong. Please try again later.", 'error')
    });

}

export const getDefaultState = (data) => async (dispatch) => {
    await dispatch({
        type: INITIAL_STATE,
		payload: {
            data,
        },
	});
}

export const handleLogout = (thisEvt) => async (dispatch) => {
    const { authData,router } = thisEvt.props;
    const url = configuration.signOut;
    thisEvt.setState({
        logoutLoading: true
    });
    // let payload = {
    //     token: authData?.authToken
    // }
    axios.get(url, {
            headers: {
                "Authorization": `Bearer ${authData?.authToken}`
            }
        }
    ).then((res) => {
        if(res.data.success) {
            dispatch({
                type: LOGOUT_ACTION,
                payload: {
                    isAuthenticated: false,
                },
            });
            let cookies = document.cookie.split(";");
        
            for (let i = 0; i < cookies.length; i++) {
                let cookie = cookies[i];
                let eqPos = cookie.indexOf("=");
                let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
            router.navigate('/auth/login');
            thisEvt.setState({
                logoutLoading: false
            })
            toastAlert(res.data?.message ? res.data?.message : "User signed out successfully", "success");
        }
    }).catch((err) => {
        console.log(err)
        if(err.response.data.code === 401) {
            document.cookie.split(";").forEach((c) => {
                document.cookie = c
                    .replace(/^ +/, "")
                    .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
            });
            dispatch({
                type: LOGOUT_ACTION,
                payload: {isAuthenticated: false,},
            })
            thisEvt.props?.history?.push("/login");
        }
        thisEvt.setState({
            logoutLoading: false
        })
        toastAlert(err?.response?.data?.message ? err?.response?.data?.message : "Something went wrong. Please try again later.", "error" );
    })
};

export const handleAuthTokenError = (props) => async (dispatch) => {
	document.cookie.split(";").forEach((c) => {
		document.cookie = c
			.replace(/^ +/, "")
			.replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
	});
	dispatch({
		type: LOGOUT_ACTION,
		payload: {isAuthenticated: false,},
	})
	props?.history?.push("/login");
}