import React, { Component } from "react";
import { connect } from "react-redux";
import "./defaultHeader.css";
import { withRouter } from "../../../helper/withRouter";
import { handleLogout } from "../../../redux/actions/authActions";
import LanguageContext from "../../../context/LanguageProvider";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  NavItem,
} from "reactstrap";
import Cookies from "js-cookie";
import { configuration } from "../../../helper/appConfig";
import axios from "axios";
import toastAlert from "../../../helper/ToastAlert/ToastAlert";

const langArr = [
  // {
  //   lang: "DE",
  //   flag: 'ch',
  //   value: 'DE'
  // },
  {
    lang: "CH-DE",
    flag: 'ch',
    value: 'CH-DE'
  },
  {
    lang: "US-EN",
    flag: 'us',
    value: "US"
  }
]

export class DefaultHeader extends Component {
  static contextType = LanguageContext;
  constructor() {
    super();
    this.state = {
      logoutLoading: false,
      openMenu: false,
      languageArr: [],
      client_logo: ""
    };
  }

  handleChange = (lang) => {
    const { userLanguageChange } = this.context;
    // const { value, name } = e.target;
    userLanguageChange(lang);
    // this.setState({
    //     lang: value,
    // },() => );
  };

  toggle = () => {
    const { openMenu } = this.state;
    this.setState({
      openMenu: !openMenu,
    })
  }

  componentDidMount = async () => {
    this.getLanguageDropdownOption();
}

  getLanguageFlag = (language = Cookies.get('lang')) => {
    const { languageArr } = this.state;
    let result = languageArr.filter((obj) => obj.code === language)[0];
    return result?.language_flag;
  }

  getLanguageDropdownOption = () => {
    const { userLanguageChange, language } = this.context;
    const url = configuration.getAllSetting;
    const headers = {
      Authorization: `Bearer ${Cookies.get("authToken")}`,
   };
    axios.get(url,{headers})
    .then((res) => {
        if(res.data.success) {
            Cookies.set('defaultInsuranceClient',res?.data?.data?.default_insurance_client)
            this.setState({  
                languageArr: res.data.data.langauge,
                client_logo: res.data.data.client_logo
            }, () => {
                if(this.state.languageArr.length && !Cookies.get('lang')) {
                    userLanguageChange(this.state.languageArr[0].code)
                }
            });
        }
    })
    .catch((err) => {
      if (err.response.data.code === 401) {
          this.props.handleAuthTokenError(this.props);
      }
      toastAlert(
          err?.response?.data.message
            ? err?.response?.data.message
            : "Something went wrong. Please try again later.",
          "error"
      );
    })
  }

  render() {
    const { logoutLoading,openMenu, languageArr } = this.state;
    const { navigate, location: { pathname }} = this.props.router;
    const { lang, language } = this.context;
    return (
      <section>
        <div className="main-nav">
          <header className="header-main">
            <div className="container-fluid">
              <div className="row">
                <nav className="navbar navbar-expand-lg navbar-expand-md p-0 w-100">
                  <div className="col-lg-2 col-md-2 col-12 p-0 logo-and-toggle-main">
                    <div className="logo-and-toggle-inner">
                      <a className="navbar-brand navbar-logo" href="/">
                        <img src="/assets/images/dns-logo.png" alt="dns-logo" />
                      </a>
                      <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <i className="fa fa-bars"></i>
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-10 col-md-10 col-12">
                    <div
                      className="collapse navbar-collapse"
                      id="navbarSupportedContent"
                    >
                      <ul className="w-100 navbar-nav header-ul-list d-flex align-items-lg-center justify-content-end align-items-md-center ml-auto">
                        {/* <li className="nav-item">
                          <div>
                            <select
                              className="logout-img lang-selection-wrapper"
                              id="lang"
                              value={language}
                              onChange={(e) => this.handleChange(e)}
                            >
                              <option
                                className="small-text-dark-gray"
                                value="US"
                              >
                                EN
                              </option>
                              <option
                                className="small-text-dark-gray"
                                value="DE"
                              >
                                DE
                              </option>
                            </select>
                          </div>
                        </li> */}
                          <NavItem className="flag_dropdown">
                            <Dropdown
                              isOpen={openMenu}
                              toggle={this.toggle}
                              id="lang"
                              value={language}
                            >
                              <DropdownToggle caret>
                                <img src={this.getLanguageFlag(language)} alt="Flgs Icon" />
                              </DropdownToggle>
                              <DropdownMenu>
                                {languageArr.map((langObj,i) => {
                                  const {language_flag, code} = langObj;
                                  return <DropdownItem onClick={() => this.handleChange(code)} key={i}>
                                    <img src={language_flag} alt={code} /> {code}
                                  </DropdownItem>
                                }
                                )}
                              </DropdownMenu>
                            </Dropdown>
                          </NavItem>
                        <li className={`nav-item dropdown`}>
                          <a
                            className="nav-link dropdown-toggle small-text-light-gray d-flex align-items-center profile_user"
                            href=""
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-expanded="false"
                            data-link-name="e-ticks"
                          >
                            <div className="user-img">
                              <img src="/assets/images/marti_stefan.jpeg" />
                            </div>
                          </a>
                          <div
                            className="dropdown-menu dropdown-menu-right"
                            aria-labelledby="navbarDropdown"
                          >
                            {[{label: 'drive_in', route: '/allocation/week'}, {label: 'user_list', route: '/userList'}, {label: 'overview', route: '/overview'}, {label: 'rental_scanners_overview', route: '/overview/rental'}, {label: 'dashboard', route: '/'}].map((ele,i) => {
                              if(pathname !== ele.route)
                              return (<button
                                      className="dropdown-item small-text-light-gray"
                                      onClick={() => navigate(ele.route)}
                                      key={i}
                                    >
                                      {lang(ele.label)}
                                    </button>)
                            })}
                            {/* {location?.pathname === '/userList' ?
                              <button
                                className="dropdown-item small-text-light-gray"
                                onClick={() => navigate("/allocation/week")}
                              >
                                {lang("drive_in")}
                              </button>
                            :
                              <button
                                className="dropdown-item small-text-light-gray"
                                onClick={() => navigate("/userList")}
                              >
                                {lang("user_list")}
                              </button>
                            }
                            {location?.pathname !== '/' &&
                              <button
                                className="dropdown-item small-text-light-gray"
                                onClick={() => navigate("/")}
                                data-link-name="dashboard"
                              >
                                {lang("dashboard")}
                              </button>
                            } */}
                            <button
                              className="dropdown-item small-text-light-gray"
                              disabled={logoutLoading}
                              onClick={() => this.props.handleLogout(this)}
                              data-link-name="Logout"
                            >
                              {lang("logout")}
                            </button>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </header>
        </div>
      </section>
    );
  }
}

// export default DefaultHeader

const mapStateToProps = (state) => ({
  authData: state.authReducer,
});

export default withRouter(
  connect(mapStateToProps, {
    handleLogout,
  })(DefaultHeader)
);
