import React, { Component } from 'react';
import './profile.css';
import { connect } from 'react-redux';
import { handleAuthTokenError } from "../../redux/actions/authActions";
import toastAlert from '../../helper/ToastAlert/ToastAlert';
import LanguageContext from '../../context/LanguageProvider';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Spinner } from 'reactstrap';
import { configuration } from '../../helper/appConfig';

class Profile extends Component {
  static contextType = LanguageContext;

  state = {
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    primaryColor: '',
    secondaryColor: '',
    loading: false,
    userId: null,
  };

  componentDidMount() {
    this.getProfileSetting();
  }

  getProfileSetting = () => {
    this.setState({ loading: true });
    const { lang } = this.context;
    const url = configuration.getUserProfile;
    const headers = {
      Authorization: `Bearer ${Cookies.get("authToken")}`,
    };
    axios
      .get(url, { headers })
      .then((res) => {
        if (res.data.success) {
          const { email_id, first_name, last_name, mobile_no, primary_color, id, secondary_color } = res.data.data;
          this.setState({
            firstName: first_name,
            lastName: last_name,
            email: email_id,
            mobile: mobile_no,
            primaryColor: primary_color,
            secondaryColor: secondary_color,
            userId: id,
            loading: false
          });
        }
      })
      .catch((err) => {
        if (err.response.data.code === 401) {
          this.props.handleAuthTokenError(this.props);
        }
        this.setState({ loading: false });
        toastAlert(
          err?.response?.data.message
            ? err.response.data.message
            : lang("something_went_wrong._please_try_again_later."),
          "error"
        );
      });
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { lang } = this.context;
    this.setState({ loading: true });

    const { userId, firstName, lastName, primaryColor, secondaryColor, email, mobile } = this.state;
    const payload = {
      user_id: userId,
      first_name: firstName,
      last_name: lastName,
      primary_color: primaryColor,
      secondary_color: secondaryColor,
      email_id: email,
      mobile_no: mobile
    };
    const url = configuration.updateUserProfile;
    const headers = {
      Authorization: `Bearer ${Cookies.get("authToken")}`,
    };

    axios.post(url, payload, { headers })
      .then((res) => {
        if (res.data.success) {
          this.setState({ loading: false });
          toastAlert(res.data.message ?? 'Data saved successfully', 'success');
        }
      })
      .catch((err) => {
        if (err.response.data.code === 401) {
          this.props.handleAuthTokenError(this.props);
        }
        this.setState({ loading: false });
        toastAlert(
          err?.response?.data.message
            ? err.response.data.message
            : lang("something_went_wrong._please_try_again_later."),
          "error"
        );
      });
  };

  render() {
    const { firstName, lastName, email, mobile, primaryColor, secondaryColor, loading } = this.state;

    return (
      <section className="profile_main_wrapper">
        <div className="container-fluid mt-4 p-0">
          <div className='row justify-content-center'>
            <div className='col-lg-8 col-md-10 col-sm-12'>
              <div className='profile_form_details'>
                  <form onSubmit={this.handleSubmit}>
                <div className='row'>
                    <div className='col-lg-12 col-md-12 col-sm-12'>
                      <div className='profile_heading mb-3'>
                        <h2>Profile Settings</h2>
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                      <div class="form-group">
                        <label htmlFor="firstName">First Name:</label>
                        <input
                          type="text"
                          id="firstName"
                          name="firstName"
                          value={firstName}
                          onChange={this.handleInputChange}
                          disabled={loading}
                        />
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                      <div class="form-group">
                        <label htmlFor="lastName">Last Name:</label>
                        <input
                          type="text"
                          id="lastName"
                          name="lastName"
                          value={lastName}
                          onChange={this.handleInputChange}
                          disabled={loading}
                        />
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                      <div class="form-group">
                        <label htmlFor="email">Email:</label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          value={email}
                          onChange={this.handleInputChange}
                          disabled={loading}
                        />
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                      <div class="form-group">
                        <label htmlFor="mobile">Mobile Number:</label>
                        <input
                          type="tel"
                          id="mobile"
                          name="mobile"
                          value={mobile}
                          onChange={this.handleInputChange}
                          disabled={loading}
                        />
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                      <div className="color-picker-wrapper">
                        <div class="form-group">
                          <label htmlFor="primaryColorPicker">Pick a Primary Color:</label>
                          <div className='position-relative showing_input_value'>
                            <input
                              type="text"
                              value={`Primary Color - ${primaryColor.toUpperCase()}`}
                            />
                            <input
                              type="color"
                              id="primaryColorPicker"
                              name="primaryColor"
                              value={primaryColor}
                              onChange={this.handleInputChange}
                              disabled={loading}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                      <div class="form-group">
                        <label htmlFor="secondaryColorPicker">Pick a Secondary Color:</label>
                        <div className='position-relative showing_input_value'>
                          <input
                            type="text"
                            value={`Primary Color - ${secondaryColor.toUpperCase()}`}
                          />
                          <input
                            type="color"
                            id="secondaryColorPicker"
                            className='p-0'
                            name="secondaryColor"
                            value={secondaryColor}
                            onChange={this.handleInputChange}
                            disabled={loading}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-12 col-md-12 col-sm-12'>
                      <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        <button type="submit" className='btn_theme_red' disabled={loading}>
                          {loading ?
                            <Spinner />
                            :
                            "Save Profile Settings"
                          }
                        </button>
                      </div>
                    </div>
                </div>
                  </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default connect(null, { handleAuthTokenError })(Profile);
