const idb = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexDB;


const createCollectionsInIndexDB = (name) => {
    if(!idb){
      console.log("This browser dose not support IndexDB.")
      return
    }

    const request = idb.open('drive-in',1);  //create database.
    request.onerror = (event) => {
      console.log("Error",event);
      console.log("An error occurred with indexedDB");
    }

    request.onupgradeneeded = (event) => {
      const db = request.result; 
      // keyPath means unique identity ex: id, email 
      if(!db.objectStoreNames.contains('user')){
        db.createObjectStore(name, {
            keyPath : "id"
        });
      }
    };

    request.onsuccess = (event) => {
      console.log("Database open successfully");
    }
  
}

const addCollectionInIndexDB = async (collectionName,data) => {
    const dbPromise = await idb.open('drive-in',1);
    dbPromise.onsuccess = () => {
        const db = dbPromise.result;
        console.log(db,"db")
        const tx = db.transaction(collectionName,'readwrite');
        console.log(tx,"tx")
        const userData = tx.objectStore(collectionName);
        console.log(userData,"userData")
        const userDetail = userData.put(data);

        userDetail.onsuccess = () => {
            tx.oncomplete = () => {
              db.close();
            }
        }

        userDetail.onerror = (err) => {
            console.log(err,"Error occurred'")
        }
    }
}

// const getAllData = (collectionName) => {
//     const dbPromise = idb.open('drive-in',1);
//     console.log(collectionName)
//     dbPromise.onsuccess = () => {
//       const db = dbPromise.result;
//         // transaction(tableName/collectionName/store, operation)
//         const tx = db.transaction(collectionName,'readonly');

//         const userData = tx.objectStore(collectionName);
//         console.log(userData,"userdata")
//         const users = userData.getAll(); 
//         users.onsuccess = (query) => {
//           console.log(query.srcElement.result,"eee")
//           return query.srcElement.result;
//         }

//         users.onerror = () => {
//           alert("Error occurred while loading initial data");
//         }

//         // tx.oncomplete = () => {
//         //   db.close();
//         // }
//     }
// }

const getAllData = (collectionName) => {
  return new Promise((resolve, reject) => {
    const dbPromise = idb.open('drive-in',1);
      dbPromise.onsuccess = () => {
        const db = dbPromise.result;
          // transaction(tableName/collectionName/store, operation)
          const tx = db.transaction(collectionName,'readonly');
    
          const userData = tx.objectStore(collectionName);
          const users = userData.getAll(); 
          users.onsuccess = (query) => {
            resolve(query.srcElement.result);
          }
    
          users.onerror = () => {
            reject(false);
            alert("Error occurred while loading initial data");
          }
    
          // tx.oncomplete = () => {
          //   db.close();
          // }
      }
    })
}

const deleteDB = (databaseName,type) => {
  return new Promise((resolve, reject) => {
    let req = idb.deleteDatabase(databaseName);
    req.onsuccess = function () {
        console.log("Deleted database successfully");
        type !== 'unauthorize' && createCollectionsInIndexDB('user');
        resolve();
    };
    req.onerror = function () {
      console.log("Couldn't delete database");
      reject();
    };
    req.onblocked = function () {
      reject();
      console.log("Couldn't delete database due to the operation being blocked");
    }
  })
};

export { createCollectionsInIndexDB, addCollectionInIndexDB, getAllData, deleteDB}; 