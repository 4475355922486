import { Route, Routes } from "react-router-dom";
import { Suspense } from "react";
import asyncComponent from "./helper/AsyncComponent";
import PrivateRoute from "./components/Routing/PrivateRoute";
import Profile from "./pages/profile/Profile";
import Overview from "./pages/overview/Overview";

// Define async components
const Auth = asyncComponent(() => import("./pages/auth/Auth"));
const SingleAllocations = asyncComponent(() => import("./pages/singleAllocations/SingleAllocations"));
const WeekAllocations = asyncComponent(() => import("./pages/weekAllocations/WeekAllocations"));
const UserList = asyncComponent(() => import("./pages/UserList/UserList"));
const UpdateLang = asyncComponent(() => import("./pages/language/UpdateLang"));
const Dashboard = asyncComponent(() => import("./pages/dashboard/Dashboard"));
const PageNotFound = asyncComponent(() => import("./pages/pageNotFound/PageNotFound"));

const routesConfig = [
    {
        path: "/",
        element: <PrivateRoute><Dashboard /></PrivateRoute>
    },
    {
        path: "/auth/login",
        element: <Auth />
    },
    {
        path: "/allocation/week",
        element: <PrivateRoute><WeekAllocations isSingleView={false} /></PrivateRoute>
    },
    {
        path: "/allocation/single/:id",
        element: <PrivateRoute><SingleAllocations /></PrivateRoute>
    },
    {
        path: "/userList",
        element: <PrivateRoute><UserList /></PrivateRoute>
    },
    {
        path: "/profile",
        element: <PrivateRoute><Profile /></PrivateRoute>
    },
    {
        path: "/updateLang",
        element: <PrivateRoute><UpdateLang /></PrivateRoute>
    },
    {
        path: "/overview/rental",
        element: <PrivateRoute><Overview key={1} isRentalScanner={true}/></PrivateRoute>
    },
    {
        path: "/overview",
        element: <PrivateRoute><Overview key={2} /></PrivateRoute>
    },
    {
        path: "*",
        element: <PageNotFound />
    },
];

function AppRoutes() {
    return (
        <Suspense>
            <Routes>
                {routesConfig.map((route, index) => (
                    <Route
                        key={index}
                        path={route.path}
                        element={route.element}
                        exact={true}
                    />
                ))}
            </Routes>
        </Suspense>
    );
}

export default AppRoutes;