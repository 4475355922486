import React, { Component } from 'react'
import { connect } from 'react-redux';
// import { Redirect } from 'react-router-dom'
import { getAllData } from '../../helper/indexDb';
import { withRouter } from '../../helper/withRouter';
import AppBar from '../common/appBar/AppBar'
import DefaultFooter from '../common/defaultFooter/DefaultFooter'
import DefaultHeader from '../common/defaultHeader/DefaultHeader';
import { getDefaultState } from '../../redux/actions/authActions';
import { Navigate } from 'react-router-dom';
import { LanguageProvider } from '../../context/LanguageProvider';


export class PrivateRoute extends Component {
  componentDidMount = async () => {
    // await getAllData('user').then((res) => {
    //   this.props.getDefaultState({...res[0]})
    // })
  }

  render() {
    const {children,auth,router} = this.props;
    return (
      <>
        {!auth?.isAuthenticated ? 
          <>
            <Navigate to="/auth/login" />
          </>
        :
          <>
            <LanguageProvider>
              <DefaultHeader />
              {/* <AppBar > */}
                  {children}
              <DefaultFooter />
            </LanguageProvider>
          </>}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
	auth: state.authReducer,
})

export default withRouter(connect(mapStateToProps,{getDefaultState})(PrivateRoute))