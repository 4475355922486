export const url = 'https://planner-api.demopod.in/api';

export const configuration = { 
    signIn: url + '/v1/login',
    signOut: url + '/v1/logout',

    // Planner PDF
    getBookingPDFData:  url + '/v1/appointment/booking/pdf',

    // Location
    createLocation: url + '/v1/create/drivescan/location',
    cloneLocation: url + '/v1/clone/drivein',
    saveLocation: url + '/v1/create/drivescan',
    dashboard: url+ '/v1/dashboard/drivescan',
    isLocationExistingOrNot: url + "/v1/location/is-exiting-or-not",
    getSingleLocation: url + '/v1/edit/drivescan',

    getAllScannerOfOrganization: url + '/v1/list/organization/scanner',
    getAllTrailerLicensePlateOfOrganization: url + '/v1/list/trailer/licence',
    getAllTransportCompany: url + '/v1/list/transport',
    getAllTrailerOfOrganization: url + '/v1/list/trailer',
    lang: url + '/v1/language-code?lang_type=1',
    updateLang: url+'/v1/update/language-code',
    deleteCard: url + '/v1/delete/drivescan',
    confirmAndCreateCard: url + '/v1/confirm/drivescan',
    getAllInsuranceClientOfOrganization: url + "/v1/list/insurance_client",
    getInsuranceClientEmailTemplate: url + '/v1/list/insurance_client/email_template',
    // saveAppointment: url + '/v1/upload/appointment-file',

    // Calender Appointment card
    saveAppointment: url + '/v1/upload/appointment-file-v1',
    runJob: url + '/v1/run-job',
    sendEmail: url + '/v1/drivein/send_email',
    sendSMS: url + '/v1/drivein/send_sms',
    getAppointmentFileDetails: url + '/v1/drivein/appointment_file/detail',
    getDispatchCustomerList: url + '/v1/list/dispatch_customer',
    createDispatchCustomer: url + '/v1/create/dispatch_customer',
    viewCustomerData : url + "/v1/view/customer",
    getAllSetting: url + '/v1/get-all-setting',
    
    // User list API
    userList: url + '/v1/list/member',
    createUser: url + '/v1/create/member',
    updateUser: url + '/v1/update/member',
    deleteUser: url + '/v1/delete/member',
    changeUserStatus: url + '/v1/status/member',
    
    // Trailer API's
    createTrailer: url + '/v1/create/trailer',
    updateTrailer: url + '/v1/update/trailer',
    deleteTrailer: url + '/v1/delete/trailer',
    
    // scanner API's
    createScanner: url + '/v1/create/organization/scanner',
    deleteScanner: url + '/v1/delete/organization/scanner',
    updateScanner: url + '/v1/update/organization/scanner',

    // Transport Company API's
    createTransportCompany: url + '/v1/create/transport',
    deleteTransportCompany: url + '/v1/delete/transport',
    updateTransportCompany: url + '/v1/update/transport',
    
    // Hotel API's
    getAllHotelList: url + '/v1/list/hotel',
    createHotel: url + '/v1/create/hotel',
    deleteHotel: url + '/v1/delete/hotel',
    updateHotel: url + '/v1/update/hotel',

    /* Insurance client API's */
    allInsuranceClientList: url + '/v1/list/insurance_client',
    deleteInsuranceClient: url + '/v1/delete/insurance_client',
    createInsuranceClient: url + '/v1/create/insurance_client',
    updateInsuranceClient: url + '/v1/update/insurance_client',

    // PDF
    generatePDF: url + '/v1/drivein/xpert_pdf/generate',
    sendPDF: url + '/v1/drivein/xpert_pdf/send',

    // Dashboard 
    getAllLocation: url + '/v1/drivein/get-all-location',
    getDashboardChart: url + '/v1/drivein/dashboard',

    // Profile
    getUserProfile: url + '/v1/edit/profile',
    updateUserProfile: url + '/v1/update/profile',

    /* Overview */
    getOverviewList: url + '/v1/drivein/list',
    getOverviewPDF: url + '/v1/drivein/list/pdf',

    /* Order Journey */
    getOrderHistory: url + '/v1/drivein/list/customer/history',
    sendEmailSms: url + '/v1/drivein/customer/send-email-sms'
}