import React, { Component } from 'react'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './mobile-input.css';

const countryCodeObj = {
    "Switzerland": "ch",
    "Germany": "de",
    "India": "in",
    'Slovenia': 'si',
};

export class MobileInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCountryData: {},
        };
    };

    handleChange = (value, country, event, formattedValue) => {
        // this.setState({
        //     selectedCountryData: data,
        // })
        this.props.onChange(value, country);
    }
    
    render() {
        const {phoneNumber, country, invalidMobileNumber} = this.props;
        const defaultCountry = countryCodeObj[country]
    return (
        <> 
            <PhoneInput
                inputClass="form-control"
                // containerClass="phone-input-container"
                buttonClass="btn phone-input-btn"
                country={defaultCountry}
                value={phoneNumber}
                onChange={(val,data, event, formattedValue) => this.handleChange(val, data, event, formattedValue)}
                addInternationalOption={false}
                onlyCountries={['fr', 'at','de','us','cz','ro','it','nl','si','ch']} // Replace with the list of countries you want to allow
                isValid={(value, country) => this.props.isMobileNumberValid(`+${value}`,country.countryCode)}
                defaultErrorMessage='Please enter valid contact number'
            />
            {invalidMobileNumber && <span className='text-danger'>Please enter valid contact number</span>}
        </>
    )
  }
}

export default MobileInput



