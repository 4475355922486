import axios from 'axios';
import Cookies from 'js-cookie';
import React, { Component, createContext } from 'react'
import { configuration } from '../helper/appConfig';
import toastAlert from '../helper/ToastAlert/ToastAlert';
const LanguageContext = createContext({});

class LanguageProvider extends Component {
    constructor(){
        super()
        this.state = {
            lang : null,
            words: {}
        }
    }

    componentDidMount = () => {
        this.setState({
            lang: Cookies.get('lang') || 'CH-DE',
        })
        this.getLang();
    }

    getLang = async () => {
        let url = configuration.lang
        const headers = {
            Authorization: `Bearer ${Cookies.get("authToken")}`,
        };
        axios.get(url,{headers})
        .then((res) => {
            if(res?.data?.data) {
                this.setState({
                    words: res.data.data,
                });
            };
        })
        .catch((err) => {
            toastAlert(err?.response?.data?.message ? err.response.data.message : 'Something went wrong!','error');
            console.log(err,"err");
        });
    };

    lang = (word) => {
        const { lang, words } = this.state;
        return words[word]?.translations[lang] ? words[word]?.translations[lang] : (words[word]?.label || word);  
    };

    userLanguageChange = (lang) => {
        Cookies.set('lang',lang)
        this.setState({
            lang : lang
        });
    };
    
  render() {
    const { lang } = this.state;
    return (
        <LanguageContext.Provider value={{userLanguageChange: this.userLanguageChange, lang: this.lang, language: lang}}>
            {this.props.children}
        </LanguageContext.Provider>
    );
  }
}


export default LanguageContext;
export { LanguageProvider }