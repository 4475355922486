import React, { Component } from 'react'
import { Spinner, Table } from 'reactstrap'
import moment from 'moment';
import LanguageContext from '../../context/LanguageProvider';
import './Overview.css';
import axios from 'axios';
import { configuration } from '../../helper/appConfig';
import Cookies from 'js-cookie';
import toastAlert from '../../helper/ToastAlert/ToastAlert';
import { handleAuthTokenError } from '../../redux/actions/authActions';
import { connect } from 'react-redux';

const classObjMain = {
    1: 'drive_through_main_card_wrapper',
    2: 'drive_in_main_card_wrapper',
    3: 'minidrive_in_main_card_wrapper',
    4: 'rental_scanners_main_card_wrapper',
  };
  
export class Overview extends Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            overviewList: [],
            language:''
        }
    };

    componentDidMount = () => {
        this.setState({
            language: this.context.language,
        })
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.context.language !== prevState.language) {
          this.setState({
           language: this.context.language,
          }, () => {
            this.getOverViewList();
          });
        }
    }

    getOverViewList = () => {
        const { lang } = this.context;
        const { language } = this.state;
        const { isRentalScanner } = this.props;
        const queryParams = [];
        
        this.setState({
            isLoading: true
        });

        let url = configuration.getOverviewList;

        if (language) {
            queryParams.push(`lang_code=${language}`);
        }

        if (isRentalScanner) {
            queryParams.push('type=1');
        }

        if (queryParams.length > 0) {
            url += `?${queryParams.join('&')}`;
        }

        const headers = {
        Authorization: `Bearer ${Cookies.get("authToken")}`,
        };
        axios.get(url, {headers} )
        .then((res) => { 
            if (res?.data?.success) {
                this.setState({
                    overviewList: res?.data?.data || [],
                })
            }
        })
        .catch((err) => {
            if (err.response.data.code === 401) {
                this.props.handleAuthTokenError(this.props);
            }
            this.setState({
                overviewList: []
            })
            toastAlert(err?.response?.data?.message ? err?.response?.data?.message : lang("something_went_wrong._please_try_again_later."),"error");
        })
        .finally(() => {
            this.setState({
                isLoading: false
            });
        });
    }; 

    getOverviewPDF = () => {
        const { lang } = this.context;
        const { language } = this.state;
        const { isRentalScanner } = this.props;

        const queryParams = [];
        
        this.setState({
            isDownloading: true
        });


        let url = configuration.getOverviewPDF;

        if (language) {
            queryParams.push(`lang_code=${language}`);
        }

        if (isRentalScanner) {
            queryParams.push('type=1');
        }

        if (queryParams.length > 0) {
            url += `?${queryParams.join('&')}`;
        }

        const headers = {
            Authorization: `Bearer ${Cookies.get("authToken")}`,
        };

        axios.get(url, {headers} )
        .then((res) => { 
            if (res?.data?.success) {
                const base64String = res.data.data;
                const link = document.createElement('a');
                link.href = `data:application/pdf;base64,${base64String}`;
                link.download = `overview-list-${moment().format('YYYY')}`;
                link.target = '_blank';
                link.rel = 'noopener noreferrer';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.setState({
                    isDownloading: false,
                });
            }
        })
        .catch((err) => {
            if (err.response.data.code === 401) {
                this.props.handleAuthTokenError(this.props);
            }
            toastAlert(err?.response?.data?.message ? err?.response?.data?.message : lang("something_went_wrong._please_try_again_later."),"error");
        })
        .finally(() => {
            this.setState({
                isDownloading: false
            });
        });
    }; 

    render() {
        const { lang } = this.context;
        const { overviewList, isLoading, isDownloading } = this.state;
        const { isRentalScanner } = this.props;
        return (
            <>
                <div className="main_card_body main_lang_wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className='mt-3 overview_main_wrapper'>
                                    <div className='row align-items-center mb-3'>

                                        <div className='col-lg-6 col-md-6 col-sm-12'>
                                            <div className='overview_heading'>
                                            <h4>
                                                {isRentalScanner ? lang("rental_scanners") : `${lang("drive_in")} / ${lang("drive_through")}`} {moment().format('YYYY')}
                                            </h4>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-12 text-right'>
                                            <div className='overview_download'>
                                                <button 
                                                    className="btn_blue_border_small mr-1"
                                                    onClick={() => this.getOverviewPDF()}
                                                    disabled={isDownloading || !overviewList.length}
                                                >
                                                    {isDownloading ? 
                                                        <Spinner size='sm'/>
                                                    :
                                                        <i className="fa fa-download"></i>
                                                    }&nbsp; {lang("download")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='table-responsive'>
                                        <Table className='table-bordered'>
                                            <thead>
                                                <tr>
                                                    <th>{lang("sr._no.")}</th>
                                                    <th>{lang("date")} / Date</th>
                                                    <th className='drive-in-type'>Drive</th>
                                                    <th>{lang("city")} / Lieu</th>
                                                    <th>{lang(isRentalScanner ? "rental_scanners_manager" : "drive-in_manager")} / {isRentalScanner ? 'responsable de la location de scanners' : 'responsable drive-In'}</th>
                                                    <th>{lang("client")} / Donnerur d'ordre</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    isLoading ?
                                                        <tr>
                                                            <td colSpan={6} className='text-center'>
                                                                {lang('loading')}...
                                                            </td>
                                                        </tr>
                                                    : 
                                                    overviewList.length ?
                                                        overviewList?.map((el,i) => {
                                                            return <tr>
                                                                <td>{el?.sr_no}</td>
                                                                <td>{el?.date}</td>
                                                                <td className={`${classObjMain[parseInt(el.drivein_type_id)]}`}>{el?.drivein_type}</td>
                                                                <td>{el?.location}</td>
                                                                <td>{el?.manager_name}</td>
                                                                <td>{el?.client_name}</td>
                                                            </tr>
                                                        })
                                                    :
                                                        <tr>
                                                            <td colSpan={6} className='text-center'>
                                                                {lang("no_data_found")}
                                                            </td>
                                                        </tr>
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default connect(null, { handleAuthTokenError })(Overview);