import { INITIAL_STATE, LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT_ACTION, SET_LOADER } from '../types/authType';
import Cookies from 'js-cookie';

const initialState = {
    loading : false,
    authToken: Cookies.get('authToken'),
    id: Cookies.get('id'),
    globalUserId: Cookies.get('globalUserId'),
    isAuthenticated: Cookies.get('isAuthenticated'),
    email: Cookies.get('email'),
    globalOrganizationId: Cookies.get('globalOrganizationId'),
    name: Cookies.get('name'),
    organizationName: Cookies.get('organizationName'),
    organizationCountry: Cookies.get('organizationCountry'),
    regionName: Cookies.get('regionName'),
    roleId: Cookies.get('roleId'),
}

const authReducer =  (state = initialState, action) => {

    const {type, payload} = action;
    
    switch (type) {
        case SET_LOADER:
            return { ...state, loading: payload.loading };
        case LOGIN_SUCCESS:
            const {email_id,global_user_id, id, access_token, global_organization_id, name, organization_name, region_name, role_name, role_id, organization_country} = payload
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                email: email_id,
                globalOrganizationId: global_organization_id,
                name: name,
                organizationName: organization_name,
                regionName: region_name,
                organizationCountry: organization_country,
                roleType: role_name,
                roleId: role_id,
                globalUserId: global_user_id,
                id,
                authToken: access_token
            };
        case LOGIN_FAIL:
            return { ...state, loading: false, isAuthenticated: false };
        case INITIAL_STATE:
            return {...action.payload.data, ...state};
        case LOGOUT_ACTION: 
            return {...action.payload};
        default:
            return state;
    }
}

export default authReducer
