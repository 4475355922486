import React, { Component } from 'react'
import AppRoutes from './Routes';

export class App extends Component {
  
  render() {
    return (
      <div className="main_page_parent_wrapper">
        <div className="App">
          <AppRoutes />
        </div>
      </div>
    )
  }
}

export default App;